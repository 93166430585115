import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import OtpInput from 'react-otp-input';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'center'
  },
  input: {
    border: `1px solid ${theme.palette2.border.textField}`,
    borderRadius: 4,
    width: '48px !important',
    height: 40,
    fontSize: 14,
    lineHeight: '20px',
    fontWeight: 700
  },
  error: {
    border: `1px solid ${theme.palette2.border.danger}`,
  }
}));

const AppOtpInput = ({ code, length, onChange, invalid }) => {
  const classes = useStyles();
  return (
    <OtpInput
      containerStyle={classes.root}
      value={code}
      onChange={onChange}
      numInputs={length}
      separator={<span style={{ width: '8px' }}></span>}
      isInputNum={true}
      shouldAutoFocus={true}
      inputStyle={classes.input}
      hasErrored={invalid}
      errorStyle={classes.error}
    />
  );
};

AppOtpInput.propTypes = {
  code: PropTypes.string.isRequired,
  length: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  invalid: PropTypes.bool
};

AppOtpInput.defaultProps = {
  code: '',
  length: 4,
  onChange: () => {},
  invalid: false
};

export default AppOtpInput;
