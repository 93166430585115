import React, { useState, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Box, Slide } from '@material-ui/core';
import { useForm } from 'react-hook-form';

import AppModal from 'src/components/AppModal';
import AppButton from 'src/components/AppButton';
import Text from 'src/components/Text';
import Form from './Form';
import VerifyPage from 'src/components/VerifyPage';
import PostRegisterPage from './PostRegisterPage';
import { defaultFormValue } from './FormUtil';
import eventLandingService from 'src/services/EventLandingService';
import { getPayload } from './FormUtil';

const EventRegistration = ({ open, onClose }) => {
  const containerRef = useRef(null);
  const [step, setStep] = useState(1);
  const [isPhoneNumberExists, setIsPhoneNumberExists] = useState(false);
  const [invalidOtp, setInvalidOtp] = useState(false);
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
    getValues,
    setValue,
    setError
  } = useForm({
    mode: 'all',
    defaultValues: defaultFormValue
  });
  const handleFormSubmit = data => {
    eventLandingService.registerPrime(`${data.code}${data.phone_number}`).then(
      response => {
        if (response.type === 'success') {
          setStep(2);
        } else {
          if (response.message === 'OTP sent already') {
            setStep(2);
          } else {
            setError(
              'phone_number',
              {
                type: 'validate',
                message: 'Phone number already exists'
              },
              {
                shouldFocus: true
              }
            );
            setIsPhoneNumberExists(true);
          }
        }
      },
      err => {
        console.log(err);
      }
    );
  };
  const handleOtpSubmit = otp => {
    eventLandingService.verifyAndCreate(getPayload(getValues(), otp)).then(
      response => {
        if (response.confirmed) {
          setStep(3);
        } else {
          setInvalidOtp(true);
        }
      },
      err => {
        console.log(err);
        setInvalidOtp(true);
      }
    );
  };
  const handleResend = type => {
    eventLandingService
      .resendOtp(`${getValues('code')}${getValues('phone_number')}`, type)
      .then(
        response => {
          console.log(response);
        },
        err => {
          console.log(err);
        }
      );
  };
  const resetPhoneError = useCallback(() => {
    setIsPhoneNumberExists(false);
  }, [setIsPhoneNumberExists]);
  const resetOtpError = useCallback(() => {
    setInvalidOtp(false);
  }, [setInvalidOtp]);
  const handleModalClose = () => {
    reset(defaultFormValue);
    onClose();
    setStep(1);
  };
  const goToFirstStep = () => {
    setStep(1);
  };

  return (
    <AppModal
      open={open}
      onClose={handleModalClose}
      header={<Text className="title">Be A Part Of Our Community</Text>}
      footer={
        step === 1 && (
          <Box mx={{ xs: 0, md: 7.5 }}>
            <AppButton
              className={clsx('btn', 'primary')}
              onClick={handleSubmit(handleFormSubmit)}
            >
              Register now
            </AppButton>
          </Box>
        )
      }
    >
      <Box ref={containerRef}>
        {step === 1 && (
          <Slide
            direction="left"
            in={step === 1}
            container={containerRef.current}
          >
            <Box>
              <Form
                control={control}
                errors={errors}
                setValue={setValue}
                getValues={getValues}
                isPhoneNumberExists={isPhoneNumberExists}
                resetPhoneError={resetPhoneError}
              />
            </Box>
          </Slide>
        )}
        {step === 2 && (
          <Slide
            direction="left"
            in={step === 2}
            container={containerRef.current}
          >
            <Box>
              <VerifyPage
                sentTo={getValues('phone_number')}
                onVerify={handleOtpSubmit}
                onBack={goToFirstStep}
                onChange={goToFirstStep}
                onResend={handleResend}
                resetOtpError={resetOtpError}
                invalid={invalidOtp}
              />
            </Box>
          </Slide>
        )}
        {step === 3 && (
          <Slide
            direction="left"
            in={step === 3}
            container={containerRef.current}
          >
            <Box>
              <PostRegisterPage />
            </Box>
          </Slide>
        )}
      </Box>
    </AppModal>
  );
};

EventRegistration.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
};

EventRegistration.defaultProps = {
  open: false,
  onClose: () => {}
};

export default EventRegistration;
