import { client } from './apiClient';
const SESSION_KEY = 'token';

export class BaseService {
  headers = {};

  constructor(url_prefix = '') {
    this.url_prefix = url_prefix;
    this.getHeaders();
  }

  async get(url, queryParams) {
    return client.get(this.getUrl(url) + this.mapQueryParams(queryParams), {
      headers: this.headers
    });
  }

  async post(url, body, queryParams = null) {
    return client.post(
      this.getUrl(url) + this.mapQueryParams(queryParams),
      body,
      {
        method: 'POST',
        headers: this.headers
      }
    );
  }

  async put(url, body, queryParams = null) {
    return client.put(
      this.getUrl(url) + this.mapQueryParams(queryParams),
      body,
      {
        method: 'PUT',
        headers: this.headers
      }
    );
  }

  async remove(url, queryParams = null) {
    return client.delete(this.getUrl(url) + this.mapQueryParams(queryParams), {
      method: 'DELETE',
      headers: this.headers
    });
  }

  getUrl(url) {
    return this.url_prefix + url;
  }

  getHeaders() {
    this.headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json'
    };
    if (this.checkSession()) {
      let apiToken = this.getSession().api_token;
      this.headers = {
        ...this.headers,
        Authorization: `Bearer ${apiToken}`
      };
    }
  }

  getSession() {
    let session = localStorage.getItem(SESSION_KEY);
    if (session) {
      return JSON.parse(session);
    }
    return session;
  }

  checkSession() {
    return localStorage.getItem(SESSION_KEY) !== null;
  }

  mapQueryParams(queryParams) {
    return queryParams
      ? `?${Object.keys(queryParams)
          // .map(key => `${key}=${queryParams[key]}`)
          .map(key => {
            let value = queryParams[key];
            if (Array.isArray(value)) {
              value = value.join(`&${key}=`);
            }
            return `${key}=${value}`;
          })
          .join('&')}`
      : '';
  }
}
