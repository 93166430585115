import { useEffect, useState } from 'react';

const useCountdown = (seconds, onEnd) => {
  const [counter, setCounter] = useState(seconds);
  useEffect(() => {
    if (counter === 0) onEnd();
    const timer =
      counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
    return () => clearInterval(timer);
  }, [counter, onEnd]);
  return counter;
};

export { useCountdown };
