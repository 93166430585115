import React from 'react';
import PropTypes from 'prop-types';

import clsx from 'clsx';
import { Box, makeStyles } from '@material-ui/core';
import { Controller, useFormState } from 'react-hook-form';

import Text from '../Text';

const useStyles = makeStyles(theme => ({
  input: {
    fontFamily: theme.typography.fontFamily,
    width: '100%',
    border: `1px solid ${theme.palette2.border.textField}`,
    borderRadius: 4,
    padding: `${theme.spacing(1.25)}px ${theme.spacing(1)}px`,
    fontSize: 12,
    lineHeight: '20px',
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),
    '&::placeholder': {
      color: theme.palette2.text.placeholder
    },
    '&.invalid': {
      border: `1px solid ${theme.palette2.border.danger}`,
      '&:focus': {
        outline: 'none'
      }
    },
    [theme.breakpoints.up('md')]: {
      fontSize: 14
    }
  },
  label: {
    lineHeight: '20px',
    display: 'block',
    fontSize: 12,
    fontWeight: 500,
    [theme.breakpoints.up('md')]: {
      fontSize: 14
    }
  },
  error: {
    fontWeight: 400,
    fontSize: 12,
    lineHeight: '20px',
    color: theme.palette2.text.danger
  }
}));

const AppInput = ({
  id,
  label,
  name,
  error,
  control,
  type,
  placeholder,
  validation,
  py,
  disabled,
  showErrorOnTouch,
  showErrorOnSubmit
}) => {
  const classes = useStyles();
  const { isSubmitted } = useFormState({ control });
  return (
    <Box py={py}>
      {label && (
        <label htmlFor={name}>
          <Text className={classes.label}>{label}</Text>
        </label>
      )}
      <Controller
        name={name}
        control={control}
        rules={validation}
        render={({ field: { onChange, onBlur, value, name, ref } }) => {
          return type === 'textarea' ? (
            <textarea
              id={id}
              type={type}
              placeholder={placeholder}
              className={clsx(
                classes.input,
                ((showErrorOnSubmit && isSubmitted) || showErrorOnTouch) &&
                  error
                  ? 'invalid'
                  : ''
              )}
              onChange={onChange}
              onBlur={onBlur}
              value={value}
              name={name}
              ref={ref}
              autoComplete="off"
              disabled={disabled}
            />
          ) : (
            <input
              id={id}
              type={type}
              placeholder={placeholder}
              className={clsx(
                classes.input,
                ((showErrorOnSubmit && isSubmitted) || showErrorOnTouch) &&
                  error
                  ? 'invalid'
                  : ''
              )}
              onChange={onChange}
              onBlur={onBlur}
              value={value}
              name={name}
              ref={ref}
              autoComplete="off"
              disabled={disabled}
            />
          );
        }}
      />
      {((showErrorOnSubmit && isSubmitted) || showErrorOnTouch) && error && (
        <Text className={clsx(classes.label, classes.error)}>
          {error.message}
        </Text>
      )}
    </Box>
  );
};

AppInput.propTypes = {
  id: PropTypes.string.isRequired,
  type: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  name: PropTypes.string.isRequired,
  validation: PropTypes.object,
  error: PropTypes.object,
  control: PropTypes.object.isRequired,
  py: PropTypes.any,
  disabled: PropTypes.bool,
  showErrorOnTouch: PropTypes.bool,
  showErrorOnSubmit: PropTypes.bool
};

AppInput.defaultProps = {
  type: 'text',
  placeholder: '',
  validation: {},
  py: { sm: 1, md: 1.5 },
  disabled: false,
  showErrorOnTouch: false,
  showErrorOnSubmit: true
};

export default AppInput;
