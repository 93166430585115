import React from 'react';
import PropTypes from 'prop-types';

import clsx from 'clsx';
import { Box, makeStyles } from '@material-ui/core';
import { Controller, useFormState } from 'react-hook-form';
import Text from '../Text';
import DropdownImg from 'src/assets/images/dropdown.svg';

const useStyles = makeStyles(theme => ({
  input: {
    width: '100%',
    border: `1px solid ${theme.palette2.border.textField}`,
    borderRadius: 4,
    padding: `${theme.spacing(1.25)}px ${theme.spacing(1)}px`,
    fontSize: 12,
    lineHeight: '20px',
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),
    '-webkit-appearance': 'none',
    '-moz-appearance': 'none',
    appearance: 'none',
    background: `url(${DropdownImg})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '95% center',
    '&.empty': {
      color: theme.palette2.text.placeholder
    },
    '&.invalid': {
      border: `1px solid ${theme.palette2.border.danger}`,
      '&:focus': {
        outline: 'none'
      }
    },
    [theme.breakpoints.up('md')]: {
      fontSize: 14
    }
  },
  label: {
    lineHeight: '20px',
    display: 'block',
    fontSize: 12,
    fontWeight: 500,
    [theme.breakpoints.up('md')]: {
      fontSize: 14
    }
  },
  error: {
    fontWeight: 400,
    fontSize: 12,
    lineHeight: '20px',
    color: theme.palette2.text.danger
  }
}));

const AppSelect = ({
  id,
  label,
  name,
  placeholder,
  options,
  error,
  control,
  validation,
  disabled,
  py,
  showErrorOnTouch,
  showErrorOnSubmit
}) => {
  const classes = useStyles();
  const { isSubmitted } = useFormState({ control });
  return (
    <Box py={py}>
      {label && (
        <label htmlFor={name}>
          <Text className={classes.label}>{label}</Text>
        </label>
      )}
      <Box>
        <Controller
          name={name}
          control={control}
          rules={validation}
          render={({ field: { onChange, onBlur, value, name, ref } }) => (
            <select
              id={id}
              className={clsx(
                classes.input,
                ((showErrorOnSubmit && isSubmitted) || showErrorOnTouch) &&
                  error
                  ? 'invalid'
                  : '',
                value === '' ? 'empty' : ''
              )}
              onChange={onChange}
              onBlur={onBlur}
              value={value}
              name={name}
              ref={ref}
              autoComplete="off"
              disabled={disabled}
            >
              <option value="">{placeholder}</option>
              {options.map((option, i) => (
                <option key={i} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          )}
        />
      </Box>
      {((showErrorOnSubmit && isSubmitted) || showErrorOnTouch) && error && (
        <Text className={clsx(classes.label, classes.error)}>
          {error.message}
        </Text>
      )}
    </Box>
  );
};

AppSelect.propTypes = {
  id: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired
    })
  ),
  name: PropTypes.string.isRequired,
  validation: PropTypes.object,
  error: PropTypes.object,
  control: PropTypes.object.isRequired,
  py: PropTypes.any,
  disabled: PropTypes.bool,
  showErrorOnTouch: PropTypes.bool,
  showErrorOnSubmit: PropTypes.bool
};

AppSelect.defaultProps = {
  validation: {},
  placeholder: '',
  options: [],
  py: { sm: 1, md: 1.5 },
  disabled: false,
  showErrorOnTouch: false,
  showErrorOnSubmit: true
};

export default AppSelect;
