import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Box, IconButton, Dialog, makeStyles } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles(theme => ({
  header: {
    '& .title': {
      fontSize: 20,
      fontWeight: 700,
      [theme.breakpoints.down('sm')]: {
        fontSize: 16
      }
    },
    '& .close': {
      padding: 0
    }
  },
  headerBg: {
    backgroundColor: theme.palette2.background.modalHeader
  },
  headerBorder: {
    borderBottom: `1px solid ${theme.palette2.divider}`
  },
  footerBorder: {
    borderTop: `1px solid ${theme.palette2.divider}`
  },
  content: {
    maxHeight: 500,
    overflow: 'scroll',
    [theme.breakpoints.down('md')]: {
      maxHeight: 450
    }
  },
  footerShadow: {
    boxShadow: '0px 0px 44px rgba(51, 51, 51, 0.25)'
  }
}));

const AppModal = ({
  open,
  onClose,
  header,
  footer,
  children,
  disableEscapeKeyDown,
  fullScreen,
  fullWidth,
  maxWidth,
  onBackdropClick,
  headerCentered,
  headerBg,
  footerShadow,
  className,
  headerBorder,
  footerBorder
}) => {
  const classes = useStyles();
  return (
    <Dialog
      onClose={onClose}
      open={open}
      disableEscapeKeyDown={disableEscapeKeyDown}
      fullScreen={fullScreen}
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      onBackdropClick={onBackdropClick}
    >
      {header && (
        <Box
          className={clsx(
            classes.header,
            headerBg ? classes.headerBg : '',
            headerBorder ? classes.headerBorder : ''
          )}
          py={1.5}
          px={{ xs: 1.5, md: 2.25 }}
          textAlign="center"
          display="flex"
          justifyContent="center"
        >
          <Box ml={headerCentered ? 'auto' : ''}>{header}</Box>
          <Box ml="auto">
            <IconButton className="close" onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>
      )}

      <Box className={clsx(classes.content, className)}>{children}</Box>

      {footer && (
        <Box
          className={clsx(
            footerShadow ? classes.footerShadow : '',
            footerBorder ? classes.footerBorder : ''
          )}
          py={1}
          px={{ xs: 2, md: 3 }}
        >
          {footer}
        </Box>
      )}
    </Dialog>
  );
};

AppModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  header: PropTypes.node,
  footer: PropTypes.node,
  children: PropTypes.node.isRequired,
  disableEscapeKeyDown: PropTypes.bool,
  fullScreen: PropTypes.bool,
  fullWidth: PropTypes.bool,
  maxWidth: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl', false]),
  onBackdropClick: PropTypes.func,
  headerCentered: PropTypes.bool,
  headerBg: PropTypes.bool,
  headerBorder: PropTypes.bool,
  footerBorder: PropTypes.bool,
  footerShadow: PropTypes.bool,
  className: PropTypes.string
};

AppModal.defaultProps = {
  fullWidth: true,
  maxWidth: 'sm',
  headerCentered: true,
  headerBg: true,
  footerShadow: true,
  className: '',
  headerBorder: false,
  footerBorder: false
};

export default AppModal;
