export const required = {
  value: true,
  message: 'This field is required'
};
export const namePattern = {
  value: /^[a-zA-Z\s]+$/,
  message: 'Enter valid name'
};
export const emailPattern = {
  value: /\S+@\S+\.\S+/,
  message: 'The Email address entered is invalid'
};
export const passwordPattern = {
  value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*_=+-]).{8,15}$/,
  message:
    '8 - 15 characters long, with minimum one uppercase letter, one lowercase letter, one number and one special character'
};
export const phonePattern = {
  value: /^[0-9]{6,15}$/,
  message: 'Enter a valid phone number'
};
export const pincodePattern = {
  value: /^[0-9]{6}$/,
  message: 'Enter valid pincode'
};
