import {
  required,
  namePattern,
  emailPattern,
  phonePattern,
  pincodePattern
} from 'src/util/validation';
import moment from 'moment';

export const validation = {
  name: {
    required,
    pattern: namePattern
  },
  email: {
    required,
    pattern: emailPattern
  },
  dateOfBirth: {
    required
  },
  pincode: {
    required,
    pattern: pincodePattern
  },
  door: {
    required
  },
  street: {
    required
  },
  city: {
    required
  },
  state: {
    required
  },
  mobile: {
    required,
    pattern: phonePattern
  },
  code: {
    required
  }
};

export const defaultFormValue = {
  full_name: '',
  email: '',
  dob: '',
  pincode: '',
  door_no: '',
  street_name: '',
  district: '',
  state: '',
  landmark: '',
  code: '',
  phone_number: '',
  taluk: '',
  country: ''
};

export const getPayload = (formValue, otp) => ({
  phone_number: `${formValue.code}${formValue.phone_number}`,
  otp: otp,
  user: {
    full_name: formValue.full_name,
    email: formValue.email,
    phone_number: `${formValue.code}${formValue.phone_number}`,
    prime: true,
    dob: moment(formValue.dob).valueOf(),
    addresses_attributes: [
      {
        location: 'home',
        name: formValue.full_name,
        address: `${formValue.door_no}, ${formValue.street_name}`,
        taluk: formValue.taluk,
        district: formValue.district,
        state: formValue.state,
        pincode: formValue.pincode
      }
    ]
  }
});
