import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Box, makeStyles, Typography } from '@material-ui/core';
import AppButton from 'src/components/AppButton';
import AppOtpInput from 'src/components/AppOtpInput';
import Text from 'src/components/Text';
import ArrowLeft from 'src/assets/images/arrow-left.svg';
import { useCountdown } from 'src/hooks/useCountdown';

const CODE_LENGTH = 4;
const RESEND_DURATION = 30;
const useStyles = makeStyles(theme => ({
  text: {
    fontSize: 14,
    lineHeight: '20px',
    '& .link': {
      color: theme.palette2.text.link,
      textTransform: 'capitalize',
      padding: 0
    }
  },
  bolder: {
    fontWeight: 500
  },
  bold: {
    fontWeight: 700
  },
  btn: {
    fontSize: 18,
    fontWeight: 600,
    paddingLeft: theme.spacing(10),
    paddingRight: theme.spacing(10),
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
    lineHeight: '24px',
    textTransform: 'capitalize'
  },
  error: {
    color: theme.palette2.text.danger
  }
}));

const VerifyPage = ({
  sentTo,
  onChange,
  onVerify,
  onBack,
  onResend,
  invalid,
  resetOtpError
}) => {
  const classes = useStyles();
  const [code, setCode] = useState('');
  const [showOtpOnCall, setShowOtpOnCall] = useState(false);
  const [showCountdown, setShowCountdown] = useState(true);
  const handleCodeChange = code => {
    setCode(code);
  };
  const handleVerify = () => {
    onVerify(code);
  };

  const handleCountdownEnd = useCallback(() => {
    setShowCountdown(false);
  }, [setShowCountdown]);

  useEffect(() => {
    const identifier = setTimeout(() => {
      resetOtpError();
    }, 500);
    return () => {
      clearTimeout(identifier);
    };
  }, [code, resetOtpError]);

  return (
    <Box textAlign="center" px={{ xs: 2, md: 10.5 }} py={2}>
      {onBack && (
        <Box mb={3}>
          <AppButton
            className={clsx(classes.text, classes.bolder)}
            startIcon={<img src={ArrowLeft} alt="back" />}
            onClick={onBack}
            fullWidth={false}
          >
            Go back
          </AppButton>
        </Box>
      )}
      <Box mb={3}>
        <Typography className={clsx(classes.text, classes.bolder)}>
          Please enter the OTP sent to {sentTo}{' '}
          {onChange && (
            <AppButton
              className={clsx(classes.bold, 'link')}
              onClick={onChange}
              fullWidth={false}
            >
              {' '}
              . Change
            </AppButton>
          )}
        </Typography>
      </Box>
      <Box mb={3}>
        <AppOtpInput
          code={code}
          onChange={handleCodeChange}
          length={CODE_LENGTH}
          invalid={invalid}
        />
        {invalid && (
          <Box pt={1}>
            <Text className={clsx(classes.text, classes.error)}>
              Invalid OTP
            </Text>
          </Box>
        )}
      </Box>
      <Box mb={3}>
        <AppButton
          className={clsx(classes.btn, 'primary')}
          onClick={handleVerify}
          disabled={code.length < CODE_LENGTH || invalid}
        >
          Verify
        </AppButton>
      </Box>
      <Box mb={3}>
        {!showCountdown && (
          <Typography className={classes.text}>
            Not received your code?{' '}
            <AppButton
              className={clsx(classes.bold, 'link')}
              onClick={() => {
                setShowOtpOnCall(true);
                onResend('text');
              }}
              fullWidth={false}
            >
              {' '}
              Resend code
            </AppButton>
          </Typography>
        )}
        {!showCountdown && showOtpOnCall && (
          <Typography className={classes.text}>
            <AppButton
              className={clsx(classes.bold, 'link')}
              onClick={() => {
                onResend('voice');
              }}
              fullWidth={false}
            >
              Get OTP on Call
            </AppButton>
          </Typography>
        )}
        {showCountdown && (
          <Typography className={classes.text}>
            <span className={clsx(classes.bold, 'link')}>
              Resend OTP in{' '}
              <SecondsTimer
                seconds={RESEND_DURATION}
                onEnd={handleCountdownEnd}
              />{' '}
              seconds
            </span>
          </Typography>
        )}
      </Box>
    </Box>
  );
};

const SecondsTimer = ({ seconds, onEnd }) => (
  <>{useCountdown(seconds, onEnd)}</>
);

VerifyPage.propTypes = {
  sentTo: PropTypes.string.isRequired,
  onVerify: PropTypes.func.isRequired,
  onChange: PropTypes.func,
  onBack: PropTypes.func,
  onResend: PropTypes.func,
  inValid: PropTypes.bool,
  resetOtpError: PropTypes.func
};

VerifyPage.defaultProps = {
  invalid: false,
  onChange: () => {},
  onResend: () => {},
  resetOtpError: () => {}
};

export default VerifyPage;
