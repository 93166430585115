import { BaseService } from './BaseService';

class EventLandingService extends BaseService {
  async getPinDetails(pincode) {
    return this.get(`/api/v1/addresses/pincode/${pincode}`);
  }

  async registerPrime(phone_number) {
    return this.post(`/api/v1/users/register_prime`, { phone_number });
  }

  async resendOtp(phone_number, type) {
    return this.put(`/api/v1/users/resend_otp`, { phone_number, type });
  }

  async verifyAndCreate(body) {
    return this.post(`/api/v1/users/verify`, body);
  }

  async getOfferExpiryTime() {
    return this.get(`/tasters_club/offer_ends`);
  }

  async createSuggestion(body) {
    return this.post(`/api/v1/suggestions`, body);
  }
}

const eventLandingService = new EventLandingService();
export default eventLandingService;
