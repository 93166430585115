import React from 'react';
import { Box, makeStyles, Typography } from '@material-ui/core';
import CircleCheck from 'src/assets/images/circle-check.svg';

const useStyles = makeStyles(theme => ({
  root: {
    minHeight: 370,
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column'
  },
  text: {
    fontSize: 16,
    lineHeight: '24px',
    '& .link': {
      color: theme.palette2.text.link,
      textTransform: 'capitalize',
      padding: 0
    }
  }
}));

const PostRegisterPage = () => {
  const classes = useStyles();

  return (
    <Box className={classes.root} px={2} py={{ xs: 2, md: 4 }}>
      <Box px={{ xs: 4, md: 8.5 }} pt={{ xs: 0, md: 3 }} pb={{ xs: 4, md: 6 }}>
        <img src={CircleCheck} alt="tick" />
        <Box mt={{ xs: 0, md: 2.25 }}>
          <Typography className={classes.text}>
            You have successfully registered for the event. We will update you
            further about the event via email.
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default PostRegisterPage;
