import React, { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Box, makeStyles, Divider, Typography } from '@material-ui/core';
import { useWatch } from 'react-hook-form';

import AppInput from 'src/components/AppInput';
import AppSelect from 'src/components/AppSelect';
import AppDatePicker from 'src/components/AppDatePicker';
import { validation } from './FormUtil';
import eventLandingService from 'src/services/EventLandingService';
import DangerAlert from 'src/assets/images/danger-alert.svg';
import { STATES, COUNTRY_CODES } from 'src/constants';

const useStyles = makeStyles(theme => ({
  error: {
    background: theme.palette2.background.dangerAlert,
    border: `1px solid ${theme.palette2.border.dangerAlert}`,
    borderRadius: 4,
    '& .message': {
      fontSize: 14,
      lineHeight: '20px',
      color: theme.palette2.text.dangerAlert
    }
  },
  label: {
    fontWeight: 500,
    lineHeight: '20px',
    fontSize: 14
  }
}));

const Form = ({
  control,
  errors,
  setValue,
  getValues,
  isPhoneNumberExists,
  resetPhoneError
}) => {
  const classes = useStyles();

  const pincode = useWatch({
    control,
    name: 'pincode'
  });
  const phoneNumber = useWatch({
    control,
    name: 'phone_number'
  });

  const getPinDetails = useCallback(
    pincode => {
      eventLandingService.getPinDetails(pincode).then(
        response => {
          setValue('taluk', response.taluk);
          // setValue('district', response.district);
          setValue('state', response.state);
          setValue('country', response.country);
        },
        err => {
          console.log('Error occured => ', err);
        }
      );
    },
    [setValue]
  );

  useEffect(() => {
    const identifier = setTimeout(() => {
      if (pincode === '' || !pincode.match(validation.pincode.pattern.value))
        return;
      getPinDetails(pincode);
    }, 500);
    return () => {
      clearTimeout(identifier);
    };
  }, [pincode, getPinDetails]);

  useEffect(() => {
    const identifier = setTimeout(() => {
      resetPhoneError();
    }, 500);
    return () => {
      clearTimeout(identifier);
    };
  }, [phoneNumber, resetPhoneError]);

  return (
    <Box px={{ xs: 2, md: 10.5 }} py={2}>
      {isPhoneNumberExists && (
        <Box className={classes.error} p={1} mb={1} display="flex">
          <Box mr={1}>
            <img src={DangerAlert} alt="error" />
          </Box>
          <Divider orientation="vertical" flexItem />
          <Box ml={1.5}>
            <Typography className="message">
              The mobile number <b>({getValues('phone_number')})</b> is already
              registered. Register with other mobile number to participate in
              the event.
            </Typography>
          </Box>
        </Box>
      )}
      <form autoComplete="off">
        <AppInput
          id="name"
          type="text"
          name="full_name"
          label="Name"
          placeholder="Enter Name"
          validation={validation.name}
          error={errors.full_name}
          control={control}
          py={1}
          showErrorOnTouch={true}
        />
        <AppInput
          id="email"
          type="email"
          name="email"
          label="Email"
          placeholder="Enter Email"
          validation={{ ...validation.email }}
          error={errors.email}
          control={control}
          py={1}
          showErrorOnTouch={true}
        />
        <AppDatePicker
          id="date-of-birth"
          name="dob"
          label="Date of birth"
          validation={validation.dateOfBirth}
          error={errors.dob}
          control={control}
          py={1}
          showErrorOnTouch={true}
        />
        <AppInput
          id="pin-code"
          type="text"
          name="pincode"
          label="Pincode"
          placeholder="Enter Pincode"
          validation={validation.pincode}
          error={errors.pincode}
          control={control}
          py={1}
          showErrorOnTouch={true}
        />
        <AppInput
          id="door"
          type="text"
          name="door_no"
          label="Door no"
          placeholder="Enter door no, apartment number"
          validation={validation.door}
          error={errors.door_no}
          control={control}
          py={1}
          showErrorOnTouch={true}
        />
        <AppInput
          id="street"
          type="text"
          name="street_name"
          label="Street name"
          placeholder="Enter Street name"
          validation={validation.street}
          error={errors.street_name}
          control={control}
          py={1}
          showErrorOnTouch={true}
        />
        <AppInput
          id="city"
          type="text"
          name="district"
          label="City/District/Town"
          placeholder="Enter City/District/Town"
          validation={validation.city}
          error={errors.district}
          control={control}
          py={1}
          // disabled={true}
          showErrorOnTouch={true}
        />
        <AppSelect
          id="state"
          type="text"
          name="state"
          label="State"
          placeholder="Enter State"
          validation={validation.state}
          error={errors.state}
          control={control}
          py={1}
          options={STATES}
          // disabled={true}
          showErrorOnTouch={true}
        />
        <AppInput
          id="landmark"
          type="text"
          name="landmark"
          label="Landmark (Optional)"
          placeholder="Enter landmark"
          validation={validation.landmark}
          error={errors.landmark}
          control={control}
          py={1}
          showErrorOnTouch={true}
        />
        <Box py={1}>
          <Typography className={classes.label}>Mobile number</Typography>
          <Box display="flex" gridColumnGap="8px">
            <Box width="15%">
              <AppSelect
                id="code"
                type="text"
                name="code"
                placeholder="Code"
                validation={validation.code}
                error={errors.code}
                control={control}
                options={COUNTRY_CODES}
                py={0.5}
                showErrorOnTouch={true}
              />
            </Box>
            <Box width="85%">
              <AppInput
                id="mobile"
                type="text"
                name="phone_number"
                placeholder="Enter a mobile number"
                validation={{ ...validation.mobile }}
                error={errors.phone_number}
                control={control}
                py={0.5}
                showErrorOnTouch={true}
              />
            </Box>
          </Box>
        </Box>
      </form>
    </Box>
  );
};

Form.propTypes = {
  control: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  setValue: PropTypes.func.isRequired,
  getValues: PropTypes.func.isRequired,
  isPhoneNumberExists: PropTypes.bool,
  resetPhoneError: PropTypes.func
};

Form.defaultProps = {
  isPhoneNumberExists: false,
  resetPhoneError: () => {}
};

export default Form;
