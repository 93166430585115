import React, { useState, useEffect, useCallback } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { AppBar, Box, Toolbar, makeStyles, Link } from '@material-ui/core';

import AppButton from 'src/components/AppButton';
import EventRegistration from 'src/pages/Event/LandingPage/components/EventRegistration';
import LogoLg from 'src/assets/images/logo-lg.png';
import eventLandingService from 'src/services/EventLandingService';

const useStyles = makeStyles(theme => {
  return {
    root: {
      zIndex: theme.zIndex.drawer + 100,
      backgroundColor: theme.palette2.background.paper,
      boxShadow: 'none'
    },
    toolbar: {
      minHeight: 64,
      backgroundColor: theme.palette2.background.paper,
      paddingLeft: theme.spacing(0),
      paddingRight: theme.spacing(2.75),
      [theme.breakpoints.up('sm')]: {
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(6),
        borderBottom: `1px solid ${theme.palette2.divider}`
      },
      [theme.breakpoints.up('lg')]: {
        paddingLeft: theme.spacing(10),
        paddingRight: theme.spacing(18)
      }
    },
    logo: {
      width: 147,
      height: 44,
      objectFit: 'cover'
    }
  };
});

const TopBar = ({ className, ...rest }) => {
  const classes = useStyles();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [offerExpired, setOfferExpired] = useState(false);
  const handleModalOpen = () => {
    setIsModalOpen(true);
  };
  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const getOfferExpiryTime = useCallback(() => {
    eventLandingService.getOfferExpiryTime().then(
      response => {
        const currentTime = new Date().getTime();
        let startTime = currentTime;
        let endTime = currentTime;
        try {
          startTime = new Date(parseInt(response.start_at)).getTime();
          endTime = new Date(parseInt(response.close_at)).getTime();
        } catch {
          console.log('Invalid expiration date', response.close_at);
        }
        if (currentTime < startTime || currentTime >= endTime) {
          setOfferExpired(true);
          return;
        }
      },
      err => {
        setOfferExpired(true);
        console.log(err);
      }
    );
  }, [setOfferExpired]);

  useEffect(() => {
    getOfferExpiryTime();
  }, [getOfferExpiryTime]);
  return (
    <div>
      <AppBar className={clsx(classes.root, className)} {...rest}>
        <Toolbar mx={5} className={classes.toolbar}>
          <Box mr={{ sm: 0.5, md: 1, lg: 1.5 }}>
            <Link component={RouterLink} to="/" underline="none">
              <img className={classes.logo} alt="Logo" src={LogoLg} />
            </Link>
          </Box>
          <Box ml={2} flexGrow={1} />
          {!offerExpired && (
            <Box ml={{ sm: 1, md: 2 }}>
              <AppButton className="primary" onClick={handleModalOpen}>
                Register Now
              </AppButton>
            </Box>
          )}
        </Toolbar>
      </AppBar>
      <EventRegistration open={isModalOpen} onClose={handleModalClose} />
    </div>
  );
};

TopBar.propTypes = {
  className: PropTypes.string
};

export default TopBar;
