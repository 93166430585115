import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import clsx from 'clsx';
import { Box, makeStyles } from '@material-ui/core';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { Controller, useFormState } from 'react-hook-form';
import Text from '../Text';
import Calendar from 'src/assets/images/calendar.svg';

const theme = createMuiTheme({
  overrides: {
    MuiFormControl: {
      root: {
        width: '100%'
      }
    },
    MuiOutlinedInput: {
      root: {
        '& $notchedOutline': {
          borderColor: '#EAEAEA'
        },
        '&:hover $notchedOutline': {
          borderColor: '#EAEAEA'
        }
      },
      adornedEnd: {
        paddingRight: 0
      }
    },
    MuiFormHelperText: {
      root: {
        '&.Mui-error': {
          color: '#D72D30'
        }
      },
      contained: {
        marginLeft: 0
      }
    }
  }
});
const useStyles = makeStyles(theme => ({
  input: {
    padding: `${theme.spacing(1.5)}px ${theme.spacing(1)}px`,
    fontSize: 12,
    lineHeight: '20px',
    [theme.breakpoints.up('md')]: {
      fontSize: 14
    }
  },
  label: {
    lineHeight: '20px',
    display: 'block',
    fontSize: 12,
    fontWeight: 500,
    [theme.breakpoints.up('md')]: {
      fontSize: 14
    }
  },
  error: {
    fontWeight: 400,
    fontSize: 12,
    lineHeight: '20px',
    color: theme.palette2.text.danger
  },
  danger: {
    border: `1px solid ${theme.palette2.border.danger}`,
    borderRadius: 4
  }
}));

const AppDatePicker = ({
  id,
  label,
  name,
  error,
  control,
  validation,
  format,
  inputVariant,
  leftArrowIcon,
  leftArrowButtonProps,
  rightArrowIcon,
  rightArrowButtonProps,
  allowKeyboardControl,
  animateYearScrolling,
  autoOk,
  disabled,
  disableFuture,
  disablePast,
  disableToolbar,
  invalidDateMessage,
  KeyboardButtonProps,
  keyboardIcon,
  maxDate,
  maxDateMessage,
  minDate,
  minDateMessage,
  onMonthChange,
  onYearChange,
  openTo,
  orientation,
  readOnly,
  variant,
  cancelLabel,
  clearable,
  clearLabel,
  DialogProps,
  okLabel,
  showTodayButton,
  todayLabel,
  disabledDates,
  py,
  showErrorOnTouch,
  showErrorOnSubmit
}) => {
  const classes = useStyles();
  const { isSubmitted } = useFormState({ control });
  const getValue = value => {
    return value || null;
  };
  const shouldDisableDate = day => {
    return disabledDates.some(str => {
      const date = moment(str);
      return (
        date.date() === day.date() &&
        date.month() === day.month() &&
        date.year() === day.year()
      );
    });
  };
  return (
    <Box py={py}>
      {label && (
        <label htmlFor={name}>
          <Text className={classes.label}>{label}</Text>
        </label>
      )}
      <Box className={error ? classes.danger : ''} my={0.5}>
        <Controller
          name={name}
          control={control}
          rules={validation}
          render={({ field: { onChange, onBlur, value, name } }) => (
            <MuiThemeProvider theme={theme}>
              <KeyboardDatePicker
                className={
                  ((showErrorOnSubmit && isSubmitted) || showErrorOnTouch) &&
                  error
                    ? 'invalid'
                    : ''
                }
                id={id}
                format={format}
                name={name}
                value={getValue(value)}
                onBlur={onBlur}
                onChange={e => {
                  const formattedValue = e?.format('YYYY-MM-DD') ?? e;
                  return onChange(formattedValue);
                }}
                InputProps={{ classes: { input: classes.input } }}
                inputVariant={inputVariant}
                leftArrowIcon={leftArrowIcon}
                rightArrowIcon={rightArrowIcon}
                leftArrowButtonProps={leftArrowButtonProps}
                rightArrowButtonProps={rightArrowButtonProps}
                allowKeyboardControl={allowKeyboardControl}
                animateYearScrolling={animateYearScrolling}
                autoOk={autoOk}
                disabled={disabled}
                disableFuture={disableFuture}
                disablePast={disablePast}
                disableToolbar={disableToolbar}
                invalidDateMessage={invalidDateMessage}
                KeyboardButtonProps={KeyboardButtonProps}
                keyboardIcon={keyboardIcon}
                maxDate={maxDate}
                maxDateMessage={maxDateMessage}
                minDate={minDate}
                minDateMessage={minDateMessage}
                onMonthChange={onMonthChange}
                onYearChange={onYearChange}
                openTo={openTo}
                orientation={orientation}
                readOnly={readOnly}
                shouldDisableDate={shouldDisableDate}
                variant={variant}
                cancelLabel={cancelLabel}
                clearable={clearable}
                clearLabel={clearLabel}
                DialogProps={DialogProps}
                okLabel={okLabel}
                showTodayButton={showTodayButton}
                todayLabel={todayLabel}
              />
            </MuiThemeProvider>
          )}
        />
      </Box>
      {((showErrorOnSubmit && isSubmitted) || showErrorOnTouch) && error && (
        <Text className={clsx(classes.label, classes.error)}>
          {error.message}
        </Text>
      )}
    </Box>
  );
};

AppDatePicker.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  validation: PropTypes.object,
  error: PropTypes.object,
  control: PropTypes.object.isRequired,
  format: PropTypes.string,
  inputVariant: PropTypes.oneOf(['standard', 'outlined', 'filled']),
  leftArrowIcon: PropTypes.node,
  rightArrowIcon: PropTypes.node,
  leftArrowButtonProps: PropTypes.object,
  rightArrowButtonProps: PropTypes.object,
  allowKeyboardControl: PropTypes.bool,
  animateYearScrolling: PropTypes.bool,
  autoOk: PropTypes.bool,
  disabled: PropTypes.bool,
  disableFuture: PropTypes.bool,
  disablePast: PropTypes.bool,
  disableToolbar: PropTypes.bool,
  invalidDateMessage: PropTypes.string,
  KeyboardButtonProps: PropTypes.object,
  keyboardIcon: PropTypes.node,
  maxDate: PropTypes.string, // yyyy-mm-dd
  maxDateMessage: PropTypes.string,
  minDate: PropTypes.string, // yyyy-mm-dd
  minDateMessage: PropTypes.string,
  onMonthChange: PropTypes.func,
  onYearChange: PropTypes.func,
  openTo: PropTypes.oneOf(['date', 'year', 'month']),
  orientation: PropTypes.oneOf(['portrait', 'landscape']),
  readOnly: PropTypes.bool,
  shouldDisableDate: PropTypes.func,
  variant: PropTypes.oneOf(['dialog', 'inline', 'static']),
  cancelLabel: PropTypes.node,
  clearable: PropTypes.bool,
  clearLabel: PropTypes.string,
  DialogProps: PropTypes.object,
  okLabel: PropTypes.string,
  showTodayButton: PropTypes.bool,
  todayLabel: PropTypes.string,
  disabledDates: PropTypes.arrayOf(PropTypes.string), // [ 'yyyy-mm-dd' ]
  py: PropTypes.any,
  showErrorOnTouch: PropTypes.bool,
  showErrorOnSubmit: PropTypes.bool
};

AppDatePicker.defaultProps = {
  validation: {},
  format: 'DD-MM-YYYY',
  inputVariant: 'outlined',
  keyboardIcon: <img src={Calendar} alt="calendar" />,
  disabledDates: [],
  py: { sm: 1, md: 1.5 },
  showErrorOnTouch: false,
  showErrorOnSubmit: true
};

export default AppDatePicker;
