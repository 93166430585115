import React, { Suspense, Fragment, lazy } from 'react';
import { Switch, Route } from 'react-router-dom';
import LoadingScreen from 'src/devias/components/LoadingScreen';
import AppLayout from './layouts/AppLayout';
import EventLayout from './layouts/EventLayout';
import ProfileLayout from './layouts/ProfileLayout';
import CookingLayout from './layouts/CookingLayout';
import AuthGuard from 'src/components/AuthGuard';

export const renderAppRoutes = (routes = []) => (
  <Suspense fallback={<LoadingScreen />}>
    <Switch>
      {routes.map((route, i) => {
        const Guard = route.guard || Fragment;
        const Layout = route.layout || Fragment;
        const Component = route.component;

        return (
          <Route
            key={i}
            path={route.path}
            exact={route.exact}
            render={props => (
              <Guard>
                <Layout>
                  {route.routes ? (
                    renderAppRoutes(route.routes)
                  ) : (
                    <Component {...props} />
                  )}
                </Layout>
              </Guard>
            )}
          />
        );
      })}
    </Switch>
  </Suspense>
);

const routes = [
  {
    path: '/profile',
    layout: ProfileLayout,
    guard: AuthGuard,
    routes: [
      {
        exact: true,
        path: '/profile',
        component: lazy(() =>
          import('src/layouts/ProfileLayout/ProfileForm/Profile')
        )
      },
      {
        exact: true,
        path: '/profile/preferences',
        component: lazy(() =>
          import('src/layouts/ProfileLayout/ProfileForm/Preferences')
        )
      },
      {
        exact: true,
        path: '/profile/recent-recipes',
        component: lazy(() =>
          import('src/layouts/ProfileLayout/ProfileForm/RecentRecipes')
        )
      },
      {
        exact: true,
        path: '/profile',
        component: lazy(() =>
          import('src/layouts/ProfileLayout/ProfileForm/Profile')
        )
      },
      {
        exact: true,
        path: '/profile/terms',
        component: lazy(() =>
          import('src/layouts/ProfileLayout/TermsAndCondition')
        )
      },
      {
        exact: true,
        path: '/profile/ratings',
        component: lazy(() => import('src/layouts/ProfileLayout/Ratings'))
      }
    ]
  },
  {
    path: '/cooking-mode',
    layout: CookingLayout,
    routes: [
      {
        exact: true,
        path: '/cooking-mode/recipe/:recipeName',
        component: lazy(() => import('src/pages/CookingMode/Ingredients'))
      },
      {
        exact: true,
        path: '/cooking-mode/recipe/:recipeName/steps',
        component: lazy(() => import('src/pages/CookingMode/Steps'))
      }
    ]
  },
  {
    path: '/',
    layout: AppLayout,
    routes: [
      {
        exact: true,
        path: '/',
        component: lazy(() => import('src/pages/Home'))
      },
      {
        exact: true,
        path: '/collections/:collectionName',
        component: lazy(() => import('src/pages/Home/RecipeList'))
      },
      {
        exact: true,
        path: '/search',
        component: lazy(() => import('src/pages/SearchPage'))
      },
      {
        exact: true,
        path: '/categories',
        component: lazy(() => import('src/pages/recipe/RecipeList'))
      },
      {
        exact: true,
        path: '/categories/:category/:subcategory',
        component: lazy(() => import('src/pages/recipe/RecipeListByCategory'))
      },
      {
        exact: true,
        path: '/recipes/:recipeName',
        component: lazy(() => import('src/pages/recipe/RecipeDetail'))
      },
      {
        exact: true,
        path: '/recipes/:recipeId/tips',
        component: lazy(() => import('src/pages/recipe/RecipeTips'))
      }
    ]
  }
];

export default routes;
